import { Suspense, useState } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"

import { useExercise } from "./ExerciseLoader.js"
import { exerciseByMethod } from "./exercises/index.js"
import { gettext } from "./i18n.js"
import { generatePath, routes } from "./routes.js"

const BEFORE = 1
const EXERCISE = 2
const AFTER = 3

const initialState = {
  score: 0,
  stage: BEFORE,
  exerciseIds: [],
  bucks: 0,
  experience: 0,
  wins: 0,
  exercise: null,
}

function exerciseFromState(topic, state) {
  const step = topic.steps[state.exerciseIds.length]
  // console.debug("exerciseFromState", { topic, state, step })
  if (step) {
    for (const exercise of step) {
      if (state.score >= exercise.score) {
        // console.debug("exerciseFromState", { state, step, exercise })
        return exercise
      }
    }
  }
}

export function Challenge({ district, topic }) {
  const [state, setState] = useState(() => ({
    ...initialState,
    exercise: exerciseFromState(topic, initialState),
  }))

  const navigate = useNavigate()
  const closeExercise = () => {
    const districtPath = generatePath(routes.district, district)
    navigate(districtPath)
  }

  console.debug("Challenge state", state)
  let component

  if (!state.exercise) {
    component = <ResultStage state={state} closeExercise={closeExercise} />
  } else if (state.stage === BEFORE && state.exercise.before.length) {
    component = (
      <DialogStage
        dialog={state.exercise.before}
        handleFinished={() => {
          setState((state) => ({ ...state, stage: EXERCISE }))
        }}
      />
    )
  } else if (state.stage === AFTER && state.exercise.after.length) {
    component = (
      <DialogStage
        dialog={state.exercise.after}
        handleFinished={() => {
          setState((state) => ({
            ...state,
            stage: BEFORE,
            exercise: exerciseFromState(topic, state),
          }))
        }}
      />
    )
  } else {
    component = (
      <ExerciseStage
        district={district}
        ex={state.exercise}
        closeExercise={closeExercise}
        handleFeedback={(feedback) => {
          console.debug("handleFeedback", feedback)
          // XXX this sucks, why do we have to do this?
          if (!state.exerciseIds.includes(feedback.exerciseId)) {
            const newState = {
              ...state,
              exerciseIds: [...state.exerciseIds, feedback.exerciseId],
              score: state.score + feedback.score,
              bucks: state.bucks + (feedback.bucks || 0),
              experience: state.experience + (feedback.experience || 0),
              wins: state.wins + (feedback.isWin ? 1 : 0),
            }

            if (state.exercise.after.length) {
              newState.stage = AFTER
            } else {
              newState.stage = BEFORE
              newState.exercise = exerciseFromState(topic, newState)
            }

            setState(newState)
          }
        }}
      />
    )
  }

  return (
    <>
      <ChallengeHud topic={topic} state={state} />
      {component}
    </>
  )
}

function ChallengeHud({ topic, state }) {
  return (
    <section className="challenge__hud">
      <h1 className="challenge__title">
        {gettext("Challenge")}: {topic.name}
      </h1>

      <div className="challenge__hud-progress">
        {topic.steps.map((_step, index) => (
          <div
            className={`challenge__hud-step ${state.exerciseIds.length === index ? "active" : ""}`}
            key={index}
          >
            <span>{index + 1}</span>
          </div>
        ))}
      </div>

      <p>
        Step {state.exerciseIds.length + 1} / {topic.steps.length} | Score:{" "}
        {state.score}, Wins: {state.wins}, Bucks: {state.bucks}, XP:
        {state.experience}
      </p>

      <p />
    </section>
  )
}

/* TODO these positions are relative to the viewport instead of relative to the pannable... */
const SPEECH_BUBBLE_POSITIONS = {
  Anna: {
    left: "23%",
    top: "29%",
  },
  Lea: {
    right: "22%",
    top: "44%",
  },
  Mike: {
    right: "22%",
    top: "29%",
  },
  Alistair: {
    left: "22%",
    top: "10%",
  },
  Sashura: {
    left: "24%",
    top: "44%",
  },
  Novella: {
    right: "22%",
    top: "10%",
  },
  Myself: {
    right: "25%",
    bottom: "1%",
  },
}

function DialogStage({ dialog, handleFinished }) {
  const [index, setIndex] = useState(0)
  const d = dialog[index]
  const pos = SPEECH_BUBBLE_POSITIONS[d.expert]
  const advance = () => {
    if (index < dialog.length - 1) {
      setIndex(index + 1)
    } else {
      handleFinished()
    }
  }

  const pannableContent = document.querySelector(".pannable-content")
  if (!pannableContent) return null

  return createPortal(
    <div
      className={`challenge__meet ${d.expert}-is-speaking`}
      onClick={advance}
      onKeyPress={advance}
    >
      <div
        className="challenge__speechbubble"
        style={{
          ...pos,
        }}
      >
        <p className="challenge__speech">
          <span>{d.expert}</span>: «{d.text}»
        </p>
      </div>
      <button
        style={{
          position: "absolute",
          right: "2%",
          bottom: "2%",
        }}
        type="button"
        className="button"
        onClick={advance}
      >
        {gettext("Continue")}
      </button>
    </div>,
    pannableContent,
  )
}

function ResultStage({ state, closeExercise }) {
  const pannableContent = document.querySelector(".pannable-content")
  if (!pannableContent) return null

  return createPortal(
    <span
      style={{
        background: "white",
        color: "black",
        display: "inline-block",
        padding: "1rem",
      }}
    >
      <h1>{gettext("Challenge finished!")}</h1>
      <p>
        Score: {state.score}, Wins: {state.wins}, Bucks: {state.bucks}, XP:{" "}
        {state.experience}
      </p>
      <button type="button" className="button" onClick={closeExercise}>
        {gettext("Close")}
      </button>
    </span>,
    pannableContent,
  )
}

function ExerciseStage({ district, ex, closeExercise, handleFeedback }) {
  const { exercise, error } = useExercise(ex.url)
  const ExerciseImpl = exerciseByMethod(exercise?.exercise?.method)

  if (!exercise) {
    return (
      <div className="overlay">
        <h1>{gettext("Loading exercise...")}</h1>
        {error ? (
          <>
            <p>{error}</p>
            <button type="button" className="button" onClick={closeExercise}>
              {gettext("Close")}
            </button>
          </>
        ) : null}
      </div>
    )
  }

  return ExerciseImpl ? (
    <Suspense fallback={gettext("Loading...")}>
      <ExerciseImpl
        {...exercise}
        district={district}
        goToDistrict={closeExercise}
        handleFeedback={handleFeedback}
      />
    </Suspense>
  ) : (
    <div className="saving is-error">
      <div className="saving-content">
        <h2>Unknown exercise method «{exercise?.exercise?.method}»</h2>
        <button type="button" className="button" onClick={closeExercise}>
          {gettext("Close")}
        </button>
      </div>
    </div>
  )

  /*
    <nav>
      {topic.steps.map((step, index) => {
        return (
          <div key={index}>
            <h2>Step {index + 1}</h2>
            <ul>
              {step.map((exercise) => (
                <li key={exercise.id}>
                  {exercise.name} (Starting with {exercise.score} points)
                  <br />
                  <strong>Before:</strong> {JSON.stringify(exercise.before)}
                  <br />
                  <strong>After:</strong> {JSON.stringify(exercise.after)}
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </nav>
    */
}
