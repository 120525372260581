import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import { useErrorBoundary } from "use-error-boundary"

import { gettext } from "../i18n.js"
import { useSaving } from "../saving.js"
import { usePersistedReducer } from "../utils.js"
import { useWorldActions, useWorldState } from "../world.js"

const ExerciseContext = createContext()
export const useDispatch = () => useContext(ExerciseContext).dispatch

const CONTENT = 1
const INTERACTIVE = 2
const RESULT = 3
const SCREEN = "__SCREEN"

const screenReducer = (reducer) => (state, action) => {
  switch (action.type) {
    case SCREEN:
      return {
        ...state,
        screen: action.screen,
        _skipSave: action.screen >= RESULT,
      }
    default:
      return { ...state, exerciseState: reducer(state.exerciseState, action) }
  }
}

const screenInit = (init) => (props) => ({
  screen: props.content?.length ? CONTENT : INTERACTIVE,
  exerciseState: init(props.exercise),
})

const CloseExercisePopup = ({
  continueNow,
  continueLater,
  clobberAndClose,
  persisted,
}) => {
  return (
    <div className="saving is-success">
      <div className="saving-content">
        <h1>{gettext("Close the exercise?")}</h1>
        <p>{gettext("You didn't complete the exercise yet.")}</p>
        <button type="button" className="button" onClick={continueNow}>
          {gettext("Continue exercise")}
        </button>
        {persisted ? (
          <>
            <p>
              {gettext(
                "You can leave and continue later or start from a clean slate next time.",
              )}
            </p>
            <div className="buttons">
              <button type="button" className="button" onClick={continueLater}>
                {gettext("OK, I'll continue later")}
              </button>
              <button
                type="button"
                className="button is-warning"
                onClick={clobberAndClose}
              >
                {gettext("I want to start from a clean slate next time")}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>
              {gettext(
                "You can leave now but you will start start from a clean slate next time.",
              )}
            </p>
            <div className="buttons">
              <button
                type="button"
                className="button is-warning"
                onClick={clobberAndClose}
              >
                {gettext("I want to start from a clean slate next time")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const reducedExercise = ({
  reducer,
  initialStateFromProps,
  Implementation,
  persisted = false,
}) =>
  function ReducedExercise(props) {
    // If handleFeedback is given, we do not show the RESULT screen but
    // directly call the handleFeedback callable instead when successfully
    // completing the exercise. This is used in the challenge district to
    // directly advance to the dialog and the next exercise.
    const { content, exercise, handleFeedback } = props
    const { user } = useWorldState()
    const key = `a-${exercise.method}-${exercise.id}-user${user.id}`
    // We disable the rules checker. It complains about conditionally calling
    // a hook which isn't correct.
    const [state, dispatch] = persisted
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        usePersistedReducer(
          key,
          screenReducer(reducer),
          props,
          screenInit(initialStateFromProps),
        )
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useReducer(
          screenReducer(reducer),
          props,
          screenInit(initialStateFromProps),
        )

    const { ErrorBoundary, didCatch, error } = useErrorBoundary()

    const clobberState = useCallback(
      () => window.localStorage.removeItem(key),
      [key],
    )
    const { updateWorld } = useWorldActions()

    const { Saving, setSaveState } = useSaving({
      url: props.saveUrl,
      onSuccess: useCallback(
        (success) => {
          success.world && updateWorld(success.world)
          if (handleFeedback) {
            handleFeedback(success.feedback)
          } else {
            dispatch({ type: SCREEN, screen: RESULT })
          }
        },
        [updateWorld, handleFeedback, dispatch],
      ),
    })

    useEffect(() => {
      if (state.screen === RESULT) clobberState()
    }, [clobberState, state.screen])

    const { goToDistrict } = props
    const closeExercise = useCallback(() => {
      dispatch({ type: SCREEN, screen: CONTENT })
      goToDistrict()
    }, [dispatch, goToDistrict])

    const [showClose, setShowClose] = useState(false)

    if (didCatch) {
      return (
        <div className="saving is-error">
          <div className="saving-content">
            <h1>{gettext("Something went wrong")}</h1>
            <code>{error.message}</code>
            <br />
            <button
              type="button"
              className="button"
              onClick={() => {
                clobberState()
                window.location.reload()
              }}
            >
              {gettext("Reset exercise and reload")}
            </button>
          </div>
        </div>
      )
    }

    return (
      <ErrorBoundary>
        <ExerciseContext.Provider value={{ dispatch }}>
          <div className={`exercise exercise--${exercise.method}`}>
            <div
              className={`exercise-screen is-content ${
                state.screen > CONTENT ? "is-gone" : "is-visible"
              }`}
            >
              <h1 className="exercise__title">{exercise.name}</h1>
              <div
                className="exercise__description richtext"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <button
                type="button"
                className="button button--start"
                onClick={() => dispatch({ type: SCREEN, screen: INTERACTIVE })}
              >
                {gettext("Start the discovery tour!")}
              </button>
              <button
                className="button button--close"
                type="button"
                onClick={() => setShowClose(true)}
              >
                {gettext("Close")}
              </button>
            </div>
            <div
              className={`exercise-screen is-interactive ${
                state.screen > INTERACTIVE ? "is-gone" : ""
              }
              ${state.screen > CONTENT ? "" : "is-freezed"}`}
            >
              <h1 className="exercise__title">{exercise.name}</h1>
              <Implementation
                ownProps={props}
                state={state.exerciseState}
                setSaveState={setSaveState}
                isCurrent={state.screen === INTERACTIVE}
              />

              <button
                className="button button--close"
                type="button"
                onClick={() => setShowClose(true)}
              >
                {gettext("Close")}
              </button>

              {content?.length ? (
                <button
                  type="button"
                  className="button button--more"
                  onClick={() => dispatch({ type: SCREEN, screen: CONTENT })}
                >
                  {gettext("Hmm, let's study some more!")}
                </button>
              ) : null}
            </div>

            <div
              className={`exercise-screen is-result ${
                state.screen > INTERACTIVE ? "is-active" : "is-freezed"
              }`}
            >
              <Saving>
                {(responseData) => {
                  return (
                    <Summary
                      stats={responseData.stats}
                      feedback={responseData.feedback}
                      closeExercise={closeExercise}
                    />
                  )
                }}
              </Saving>
            </div>

            {showClose ? (
              <CloseExercisePopup
                continueNow={() => {
                  setShowClose(false)
                }}
                continueLater={closeExercise}
                clobberAndClose={() => {
                  clobberState()
                  closeExercise()
                }}
                persisted={persisted}
              />
            ) : null}
          </div>
        </ExerciseContext.Provider>
      </ErrorBoundary>
    )
  }

const SUMMARY_BUCKS = 1
const SUMMARY_EXPERIENCE = 2
const SUMMARY_RESULTS = 3

const Summary = ({ stats, feedback, closeExercise }) => {
  const [stage, setStage] = useState(SUMMARY_BUCKS)

  console.log("Summary", { stage })

  if (stage === SUMMARY_BUCKS && stats.previousBucks === stats.bucks) {
    setStage(SUMMARY_EXPERIENCE)
    return null
  }
  if (
    stage === SUMMARY_EXPERIENCE &&
    stats.previousExperience === stats.experience
  ) {
    setStage(SUMMARY_RESULTS)
    return null
  }

  const handleClick = () => {
    if (stage < SUMMARY_RESULTS) {
      setStage(stage + 1)
    } else {
      closeExercise()
    }
  }

  return (
    <div
      className={`feedback-screen ${
        stage === SUMMARY_RESULTS ? "is-summary" : ""
      }`}
      onClick={handleClick}
    >
      {stage === SUMMARY_BUCKS ? (
        <>
          <div className="feedback__new-bucks">
            <div className="feedback__sparkles">
              {Array.from({ length: 20 }).map((_e, i) => (
                <div className="feedback__sparkle" key={i} />
              ))}
            </div>
            <div className="feedback__content">
              <h1
                className="feedback__gratulation twinkle"
                data-content={gettext("Bravo!")}
              >
                <span className="inner-twinkle">{gettext("Bravo!")}</span>
              </h1>
              <h2
                className="feedback__text twinkle"
                data-content={gettext("You gained Hero Bucks.")}
              >
                {gettext("You gained Hero Bucks.")}
              </h2>
              <div className="feedback__coin" />
              <p
                className="feedback__amount twinkle"
                data-content={`+${stats.bucks - stats.previousBucks}`}
              >
                +{stats.bucks - stats.previousBucks}
              </p>
            </div>
          </div>
        </>
      ) : null}
      {stage === SUMMARY_EXPERIENCE ? (
        <>
          {stats.previousLevel.level === stats.level.level ? (
            <>
              <div className="feedback__add-experience">
                <h1
                  className="feedback__text is-add-experience twinkle"
                  data-content={gettext("You gained experience!")}
                >
                  {gettext("You gained experience!")}
                </h1>
                <p
                  className="feedback__amount is-add-experience twinkle"
                  data-content={`+${
                    stats.experience - stats.previousExperience
                  }`}
                >
                  +{stats.experience - stats.previousExperience}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="feedback__new-level">
                <p
                  className="feedback__gratulation is-new-level twinkle"
                  data-content={gettext("New level!")}
                >
                  {gettext("New level!")}
                </p>
                <h1
                  className="feedback__amount is-new-level twinkle"
                  data-content={stats.level.level}
                >
                  {stats.level.level}
                </h1>
                <h2
                  className="feedback__text is-new-level twinkle"
                  data-content={stats.level.title}
                >
                  {stats.level.title}
                </h2>
              </div>
            </>
          )}
        </>
      ) : null}
      {stage === SUMMARY_RESULTS ? (
        <>
          <div className="feedback__summary">
            <div className="feedback__summary-content">
              <div
                className="feedback__summary-analysis"
                dangerouslySetInnerHTML={{ __html: feedback?.feedback }}
              />
            </div>
          </div>
        </>
      ) : null}
      <button
        type="button"
        className="button button--feedback-continue"
        onClick={handleClick}
      >
        {gettext("Continue")}
      </button>
    </div>
  )
}
