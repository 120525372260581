export { generatePath } from "react-router"

export const routes = {
  city: "/",
  district: "/district/:slug/*",
  exercise: "exercise/:exercise",
  challenge: "challenges/:topic",
  friendbook: "/profile/friendbook",
  shop: "/profile/shop",
}
