import { useEffect } from "react"

import { gettext } from "../i18n.js"
import { clamp, logger, sleep, timestamp } from "../utils.js"

import { Timer } from "./Timer.js"
import { reducedExercise, useDispatch } from "./base.js"

const currentQuestion = (state) => state.questions[state.index]

const START = "START"
const SAVE = "SAVE"
const NEXT = "NEXT"
const CHECK = "CHECK"

function initialStateFromProps(props) {
  return {
    questions: props.questions,
    timeLimit: props.timeLimit,
    index: 0,
    checkedAnswers: {},
    startedExerciseAt: timestamp(),
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return { ...state, startedInteractiveAt: timestamp() }

    case SAVE:
      return { ...state, finishedAt: timestamp() }

    case NEXT: {
      if (action.index >= state.questions.length - 1) {
        return state.finishedAt ? state : { ...state, finishedAt: timestamp() }
      }

      return {
        ...state,
        index: clamp(0, action.index + 1, state.questions.length - 1),
      }
    }

    case CHECK:
      return {
        ...state,
        checkedAnswers: {
          ...state.checkedAnswers,
          [action.question]: action.answer,
        },
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const saveState = (state) => {
  /*
  const answers = state.questions
    .map((question) => {
      const answer = question.answers.find(
        (answer) => answer.id === state.checkedAnswers[question.id]
      )
      return answer
        ? {
            question: question.question,
            answer: answer.answer,
            isCorrect: answer.isCorrect,
          }
        : null
    })
    .filter((answer) => !!answer)
  */

  return {
    mode: "oddOne",
    durationInteractive: state.finishedAt - state.startedInteractiveAt,
    durationExercise: state.finishedAt - state.startedExerciseAt,
    checkedAnswers: state.checkedAnswers,
    // answers: state.checkedAnswers,
    // questions: state.questions.length,
    // correct: answers.filter((answer) => answer.isCorrect).length,
    // answers,
  }
}

export function OddOneImpl({ state, setSaveState, isCurrent }) {
  const question = currentQuestion(state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.startedInteractiveAt || state.finishedAt) return

    const timeout = setTimeout(
      () => dispatch({ type: NEXT, index: state.index }),
      state.timeLimit * 1000,
    )
    return () => clearTimeout(timeout)
  }, [dispatch, state])

  console.log(state)

  useEffect(() => {
    if (!state.startedInteractiveAt && isCurrent) {
      setTimeout(() => dispatch({ type: START }), 1000)
    }
  }, [state.startedInteractiveAt, dispatch, isCurrent])

  useEffect(() => {
    if (state.finishedAt) {
      setSaveState(saveState(state))
    }
  }, [state.finishedAt]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!question) {
    return <p className="error">{gettext("No questions defined?")}</p>
  }

  return (
    <div className="a-oddone">
      <Question
        question={question}
        checkedAnswers={state.checkedAnswers}
        checkAnswer={async (answer) => {
          dispatch({
            type: CHECK,
            question: question.id,
            answer,
          })
          await sleep(500)
          dispatch({ type: NEXT, index: state.index })
        }}
      />
      {state.finishedAt ? (
        <button
          type="button"
          class="button"
          onClick={() => {
            dispatch({ type: SAVE })
          }}
        >
          {gettext("Save")}
        </button>
      ) : (
        <Timer
          key={question.id}
          timeLimit={state.timeLimit}
          isRunning={
            state.startedInteractiveAt && !state.checkedAnswers[question.id]
          }
        />
      )}
    </div>
  )
}

function Question({ question, checkedAnswers, checkAnswer }) {
  return (
    <div className="a-oddone__gameboard">
      <div
        className="a-oddone__question"
        dangerouslySetInnerHTML={{ __html: question.question }}
      />
      <div className="a-oddone__answers">
        {question.answers.map(({ id, answer }) => (
          <label key={id} className="a-oddone__answer">
            <input
              name={`question-${question.id}`}
              type="radio"
              checked={checkedAnswers[question.id] === id}
              onChange={() => checkAnswer(id)}
            />
            <div className="a-oddone__answer-label">{answer}</div>
          </label>
        ))}
      </div>
    </div>
  )
}

export const OddOne = reducedExercise({
  reducer: logger("OddOne: ", reducer),
  initialStateFromProps,
  Implementation: OddOneImpl,
})
