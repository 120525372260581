import { useCallback, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom"

import { System } from "detect-collisions"
import { createStaticCollisionObjects } from "./districtCollisions.js"

import { ExerciseLoader } from "./ExerciseLoader.js"
import { useRandomHeroes } from "./RandomHeroes.js"
import { hero } from "./assets.js"
import { Challenge } from "./challenge.js"
import { FloatingLink } from "./floatingLink.js"
import { Hero } from "./heroes.js"
import { gettext } from "./i18n.js"
import { Pannable } from "./pannable.js"
import { generatePath, routes } from "./routes.js"
import { intersect, useInitialData } from "./utils.js"
import { useWorldState } from "./world.js"

export function DistrictRouter() {
  const { districtsBySlug, user } = useWorldState()
  const { urls } = useInitialData("settings")

  const params = useParams()
  const district = districtsBySlug[params.slug]
  const districtSlug = district?.slug

  useEffect(() => {
    if (!districtSlug) return

    const fd = new FormData()
    fd.append("user", user.id)
    fd.append("district", districtSlug)
    navigator.sendBeacon(urls.userInDistrict, fd)
  }, [districtSlug, user.id, urls])

  if (!district) return <Navigate replace to={generatePath(routes.city)} />

  const exercises = district.exercises || []
  const challenges = district.challenges || []

  return (
    <>
      <Pannable
        identifier={`district-${district.district}`}
        image={district.backgroundImageUrl}
        controls={district.district !== "challenge"}
      >
        {(props) => (
          <DistrictContent
            district={district}
            exercises={exercises}
            challenges={challenges}
            user={user}
            {...props}
          />
        )}
      </Pannable>

      <FloatingLink
        name={gettext("< City")}
        url={generatePath(routes.city)}
        position={{ x: 0, y: 0 }}
        modifierClass="is-back-to-city"
      />

      <Routes>
        {exercises.map((exercise) => {
          const path = generatePath(routes.exercise, {
            slug: district.slug,
            exercise: exercise.id,
          })
          return (
            <Route
              key={exercise.id}
              path={path}
              element={
                <ExerciseLoader district={district} exercise={exercise} />
              }
            />
          )
        })}
        {challenges.map((topic) => {
          const path = generatePath(routes.challenge, {
            slug: district.slug,
            topic: topic.id,
          })
          return (
            <Route
              key={topic.id}
              path={path}
              element={<Challenge district={district} topic={topic} />}
            />
          )
        })}
        <Route
          exact
          path={""}
          element={<MaybeRedirect district={district} />}
        />
        <Route
          default
          element={
            <Navigate replace to={generatePath(routes.district, district)} />
          }
        />
      </Routes>
    </>
  )
}

function MaybeRedirect({ district }) {
  if (district.district === "swissmoneyweek") {
    return <Navigate replace to={generatePath(routes.city)} />
  }
  return null
}

function DistrictContent({
  position,
  setPosition,
  district,
  exercises,
  challenges,
  user,
}) {
  const [focus, setFocus] = useState(null)
  const toggleFocus = useCallback((exercise) => {
    setFocus((focus) => (focus === exercise ? null : exercise))
  }, [])

  useEffect(() => {
    const body = document.body
    const resetFocus = () => {
      setFocus(null)
    }
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        setFocus(null)
      }
    }

    body.addEventListener("click", resetFocus)
    body.addEventListener("keydown", escapeHandler)

    return () => {
      body.removeEventListener("click", resetFocus)
      body.removeEventListener("keydown", escapeHandler)
    }
  }, [])

  const isChallenge = district.district === "challenge"

  return (
    <>
      <Helmet>
        <title>{district.name} - FinanceMission World</title>
      </Helmet>
      <canvas /> {/* The collision debug system requires this (currently) */}
      {isChallenge ? (
        <HeroHud user={user} />
      ) : (
        <DistrictHeroes
          district={district}
          user={user}
          position={position}
          setPosition={setPosition}
        />
      )}
      {exercises.map((exercise) => {
        const identifier = `exercise${exercise.id}`
        const props = {
          identifier,
          name: exercise.name,
          description: exercise.description,
          url: generatePath(routes.exercise, {
            slug: district.slug,
            exercise: exercise.id,
          }),
          position: exercise.position,
          stateClass: [
            exercise.isCompleted ? "is-completed" : "is-unlocked",
            exercise.isSuccess ? "is-success" : "",
            exercise.isCurrentBlock ? "is-current-block" : "is-past-blocks",
            exercise.isRequired ? "is-required" : "is-optional",
          ].join(" "),
          method: exercise.method,
          modifierClass: identifier === focus ? "active" : "",
          toggleFocus,
        }

        switch (exercise.method) {
          case "resolution":
            return (
              <ResolutionLink
                district={district}
                user={user}
                {...props}
                key={exercise.id}
              />
            )
          default:
            return <ExerciseLink {...props} key={exercise.id} />
        }
      })}
      {challenges.map((topic) => {
        const identifier = `topic${topic.id}`
        const props = {
          identifier,
          name: topic.name,
          url: generatePath(routes.challenge, {
            slug: district.slug,
            topic: topic.id,
          }),
          position: topic.position,
          stateClass: "is-current-block is-required",
          /*
          stateClass: [
            exercise.isCompleted ? "is-completed" : "is-unlocked",
            exercise.isSuccess ? "is-success" : "",
            exercise.isCurrentBlock ? "is-current-block" : "is-past-blocks",
            exercise.isRequired ? "is-required" : "is-optional",
          ].join(" "),
          */
          method: topic.method,
          modifierClass: identifier === focus ? "active" : "",
          toggleFocus,
        }

        return <ExerciseLink {...props} key={topic.id} />
      })}
    </>
  )
}

function HeroHud({ user }) {
  return (
    <div className="challenge__meeting-screens">
      <div className="challenge__expert challenge__expert--Anna" />
      <div className="challenge__expert challenge__expert--Lea" />
      <div className="challenge__expert challenge__expert--Mike" />
      <div className="challenge__expert challenge__expert--Alistair" />
      <div className="challenge__expert challenge__expert--Sashura" />
      <div className="challenge__expert challenge__expert--Novella" />

      <div className="challenge__myself">
        <Hero
          className="hero is-in-district is-other"
          assetGroup={hero[user.sex].perspective}
          items={user.equippedItems}
          style={{
            left: "52%",
            bottom: "-180%",
          }}
          heroName={user.heroName}
        />
      </div>
    </div>
  )
}

function DistrictHeroes({ district, user, position, setPosition }) {
  const systemRef = useRef()
  const heroRef = useRef()

  if (!systemRef.current) {
    systemRef.current = new System()
    createStaticCollisionObjects(systemRef.current, district)
    heroRef.current = addHeroPolygon(
      systemRef.current,
      1000 * position.x,
      1000 * position.y,
    )
  }

  const { classmates } = useWorldState()
  const [randomHeroes, setRandomHeroes] = useRandomHeroes(
    classmates,
    () => addHeroPolygon(systemRef.current, 500, 500),
    district,
  )

  const { x, y } = position
  heroRef.current.x = x * 1000
  heroRef.current.y = y * 1000

  /*
  randomHeroes.forEach((hero) => {
    hero.polygon.x = hero.x * 1000
    hero.polygon.y = hero.y * 1000
  })
  */

  const { DEBUG } = useInitialData("settings")

  // biome-ignore lint/correctness/useExhaustiveDependencies: x and y are required so that recalculations happen when they should.
  useEffect(() => {
    systemRef.current.update()

    negateOverlap(systemRef.current, heroRef.current, ({ x, y }) =>
      setPosition((position) => ({
        ...position,
        x: x / 1000,
        y: y / 1000,
      })),
    )

    systemRef.current.update()

    setRandomHeroes((randomHeroes) =>
      randomHeroes.map((hero) => {
        negateOverlap(systemRef.current, hero.polygon, ({ x, y }) => {
          hero = { ...hero, x: x / 1000, y: y / 1000 }
        })
        return hero
      }),
    )

    if (DEBUG) drawCollisionSystem(systemRef.current)
  }, [x, y, setPosition, setRandomHeroes, DEBUG])

  return (
    <>
      {randomHeroes.map((hero) => (
        <Hero
          className="hero is-in-district is-other"
          assetGroup={hero.assetGroup}
          items={hero.items}
          style={{
            left: `${hero.x * 100}%`,
            top: `${hero.y * 100}%`,
          }}
          heroName={hero.heroName}
          key={hero.id}
        />
      ))}
      {
        /* Might be not set yet if onboarding is deferred */ user.sex ? (
          <Hero
            assetGroup={hero[user.sex].iso}
            className={`hero is-in-district is-self ${
              Math.random() < 0.5 ? "" : "is-inverted"
            }`}
            items={user.equippedItems}
            style={{
              left: `${position.x * 100}%`,
              top: `${position.y * 100}%`,
            }}
          />
        ) : null
      }
    </>
  )
}

function ExerciseLink(props) {
  const {
    name,
    description,
    url,
    position,
    stateClass,
    modifierClass,
    method,
    identifier,
    toggleFocus,
  } = props

  const handleClick = (e) => {
    e.stopPropagation()
    toggleFocus(identifier)
  }

  return (
    <div
      className={`floating-link is-exercise ${stateClass || ""} ${
        modifierClass || ""
      } is-${method}`}
      onClick={handleClick}
      onKeyPress={handleClick}
      style={{ left: `${position.x}%`, top: `${position.y}%` }}
    >
      <Link to={url} className="floating-link__description">
        <h3>{name}</h3>
        <small>{description}</small>
      </Link>
    </div>
  )
}

function ResolutionLink({ district, user, identifier, toggleFocus, ...props }) {
  const awarded = intersect(district.resolutionPanels, user.resolutionPanels)
  const completion =
    Math.floor((100 * awarded.length) / district.resolutionPanels.length) || 0
  const stateStyle = `${completion}%`
  const stateClass =
    completion >= 100
      ? "is-completed is-success is-resolution"
      : "is-unlocked is-resolution"

  const handleClick = (e) => {
    e.stopPropagation()
    toggleFocus(identifier)
  }

  return (
    <div
      className={`floating-link ${stateClass || ""} ${props.modifierClass || ""}`}
      onClick={handleClick}
      onKeyPress={handleClick}
      style={{
        left: `${props.position.x}%`,
        top: `${props.position.y}%`,
        "--completion": `${stateStyle || "0%"}`,
      }}
    >
      <Link to={props.url} className="floating-link__description">
        <h3>
          {props.name} ({completion}%)
        </h3>
      </Link>
    </div>
  )
}

function addHeroPolygon(system, x, y) {
  return system.createPolygon(
    {
      x,
      y,
    },
    [
      [-20, -90],
      [20, -90],
      [20, 20],
      [-20, 20],
    ].map(([x, y]) => ({ x, y })),
    0,
  )
}

function drawCollisionSystem(system) {
  const canvas = document.querySelector("canvas")
  canvas.height = 1000
  canvas.width = 1000
  canvas.style.width = "100%"
  canvas.style.height = "100%"
  canvas.style.position = "absolute"
  canvas.style.zIndex = 100
  canvas.style.border = "2px solid #fff"
  canvas.style.pointerEvents = "none"

  const context = canvas.getContext("2d")
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.strokeStyle = "#FFFFFF"
  context.beginPath()
  system.draw(context)
  context.stroke()
}

function negateOverlap(system, object, setter) {
  for (const collider of system.getPotentials(object)) {
    if (system.checkCollision(object, collider)) {
      const { overlap, overlapV } = system.response

      // Skip small pushes
      if (overlap < 5) return

      // The random element avoids updating the system too often without
      // reaching a good state
      object.x -= overlapV.x + (Math.random() - 0.5) * 40
      object.y -= overlapV.y + (Math.random() - 0.5) * 40

      setter({ x: object.x, y: object.y })
    }
  }
}
